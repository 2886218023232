<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <h3>ویرایش اطلاعات بیمار</h3>
      </v-card-title>
      <v-tabs
        class=""
        fixed-tabs
        background-color="transparent"
        v-model="tab"
        :vertical="deviceType == 'mobile' ? true : false"
      >
        <v-tab
          class="tab-title"
          :style="deviceType == 'mobile' ? 'border-radius: 0 !important' : ''"
        >
          اطلاعات عمومی
        </v-tab>
        <v-tab
          class="tab-title"
          :style="deviceType == 'mobile' ? 'border-radius: 0 !important' : ''"
        >
          تاریخچه درمانی
        </v-tab>
        <v-tab
          class="tab-title"
          :style="deviceType == 'mobile' ? 'border-radius: 0 !important' : ''"
          v-if="role != 'dentistryNurse'"
        >
          مراجعه ها
        </v-tab>
        <v-tab
          class="tab-title"
          :style="deviceType == 'mobile' ? 'border-radius: 0 !important' : ''"
          v-if="role != 'dentistryNurse'"
        >
          پرداخت ها
        </v-tab>
      </v-tabs>
      <v-card class="br-card">
        <v-card-text class="pt-1">
          <div class="pt-0">
            <v-card class="pa-3">
              <v-tabs-items v-model="tab">
                <!-- اطلاعات عمومی -->
                <v-tab-item>
                  <v-card>
                    <div
                      v-if="infoLoading"
                      class="pa-8 d-flex flex-column justify-content-center align-items-center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <h5 class="mt-5 primary--text">در حال بارگذاری...</h5>
                    </div>
                    <v-card-text v-else>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-select
                            v-model="userData.status"
                            outlined
                            dense
                            :items="states"
                            label="وضعیت"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field
                            v-model="userData.mobile"
                            outlined
                            dense
                            label="شماره موبایل"
                            type="number"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field
                            v-model="userData.firstName"
                            outlined
                            dense
                            label="نام"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field
                            v-model="userData.lastName"
                            outlined
                            dense
                            label="نام‌خانوادگی"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field
                            v-model="userData.fatherName"
                            outlined
                            dense
                            label="نام پدر"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-select
                            v-model="userData.insuranceId"
                            outlined
                            dense
                            :items="insurTypes"
                            label="نوع بیمه"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field
                            v-model="userData.insurNo"
                            outlined
                            dense
                            label="شماره بیمه"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-select
                            v-model="userData.supplementaryInsurance"
                            outlined
                            dense
                            :items="insurTypes"
                            label="نوع بیمه تکمیلی"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field
                            v-model="userData.tel"
                            outlined
                            dense
                            label="شماره تلفن ثابت"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field
                            v-model="userData.state"
                            outlined
                            dense
                            label="استان"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field
                            v-model="userData.city"
                            outlined
                            dense
                            label="شهر"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field
                            v-model="userData.email"
                            outlined
                            dense
                            label="ایمیل"
                            :rules="[validateEmail]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-select
                            v-model="userData.gender"
                            outlined
                            dense
                            :items="genders"
                            label="جنسیت"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-select
                            v-model="userData.bloodType"
                            outlined
                            dense
                            :items="bloodTypes"
                            label="گروه خونی"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field
                            v-model="userData.natCode"
                            outlined
                            dense
                            label="کد ملی"
                            type="number"
                            :rules="[isID]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <span id="bday">
                            <v-text-field
                              outlined
                              dense
                              type="text"
                              append-icon="calendar_today"
                              v-model="userData.birthday"
                              label=" تاریخ تولد "
                              :editable="true"
                              class="date-input"
                            >
                            </v-text-field>
                          </span>

                          <date-picker
                            v-model="userData.birthday"
                            element="bday"
                            color="#00a7b7"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" class="pt-0">
                          <v-checkbox
                            v-model="userData.insuranceLastSheet"
                            class="lastSheet-checkBox"
                            color="primary"
                            label="تا آخرین صفحه دفترچه"
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" class="pt-0">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            v-model="userData.insuranceExpirationDate"
                            label="تاریخ انقضا دفترچه"
                            :editable="true"
                            class="date-input mt-2"
                            :disabled="userData.insuranceLastSheet"
                          >
                            <template #append>
                              <span id="insuranceExpirationDate">
                                <v-icon class="calender-icon"
                                  >mdi-calendar-blank</v-icon
                                >
                              </span>
                            </template>
                          </v-text-field>

                          <date-picker
                            v-model="userData.insuranceExpirationDate"
                            element="insuranceExpirationDate"
                            color="#00a7b7"
                            :disabled="userData.insuranceLastSheet"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0">
                          <v-textarea
                            v-model="userData.address"
                            label="آدرس دقیق"
                            outlined
                            dense
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-btn
                        class="submit-btn primary-btn me-3 ms-3"
                        @click="editPatient()"
                        v-if="!infoLoading"
                        >ثبت</v-btn
                      >
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!-- تاریخچه درمانی -->
                <v-tab-item>
                  <v-card class="pa-2">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                          <div class="patient-div">
                            <h6 class="primary--text">بیماری‌ها</h6>
                            <v-textarea
                              outlined
                              v-model="history.diseases"
                              rows="8"
                            ></v-textarea>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                          <div class="patient-div">
                            <h6 class="primary--text">حساسیت‌ها</h6>
                            <v-textarea
                              outlined
                              v-model="history.allergies"
                              rows="8"
                            ></v-textarea>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                          <div class="patient-div">
                            <h6 class="primary--text">
                              داروهای در حال استفاده
                            </h6>
                            <v-textarea
                              outlined
                              v-model="history.drugs"
                              rows="8"
                            ></v-textarea>
                          </div>
                        </v-col>
                      </v-row>
                      <!--<template>
                        <v-expansion-panels multiple>
                          <v-expansion-panel>
                            <v-expansion-panel-header
                              >بیماری‌ها</v-expansion-panel-header
                            >
                            <v-expansion-panel-content class="my-3">
                              <v-textarea
                                outlined
                                v-model="history.diseases"
                                rows="8"
                              ></v-textarea>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header
                              >حساسیت‌ها</v-expansion-panel-header
                            >
                            <v-expansion-panel-content class="my-3">
                              <v-textarea
                                outlined
                                v-model="history.allergies"
                                rows="8"
                              ></v-textarea>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header
                              >داروهای در حال استفاده</v-expansion-panel-header
                            >
                            <v-expansion-panel-content class="my-3">
                              <v-textarea
                                outlined
                                v-model="history.drugs"
                                rows="8"
                              ></v-textarea>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </template>-->
                    </v-card-text>
                  </v-card>
                  <v-btn
                    class="submit-btn primary-btn me-3 ms-3"
                    @click="editPatient()"
                    v-if="!infoLoading"
                    >ثبت</v-btn
                  >
                </v-tab-item>
                <!-- مراجعه ها -->
                <v-tab-item v-if="role != 'dentistryNurse'">
                  <mobile-text v-if="deviceType == 'mobile'" :title="''" />
                  <v-card class="pa-2" v-else>
                    <v-text-field
                      v-model="Filter"
                      prepend-inner-icon="mdi-magnify"
                      label="جستجو"
                      single-line
                      hide-details
                      clearable
                      rounded
                      filled
                      class="text-right w-80 mb-2 search-input"
                    ></v-text-field>
                    <b-table
                      responsive
                      show-empty
                      :fields="Fields"
                      :items="Items"
                      empty-text="مراجعه‌ای برای این بیمار وجود ندارد"
                      empty-filtered-text="مراجعه‌ای برای این بیمار وجود ندارد"
                      :busy="Busy"
                      :filter="Filter"
                      :current-page="CurrentPage"
                      :per-page="PerPage"
                      @filtered="onFiltered"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.value }}
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                        </div>
                      </template>
                      <template v-slot:cell(operation)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <v-btn
                            v-if="data.item.visitType == 'آزمایشگاه'"
                            class="primary-btn pa-3"
                            :disabled="role == 'reception'"
                            :to="'/admin/labRequestId/' + data.item.id"
                          >
                            <v-icon> visibility</v-icon>
                          </v-btn>
                          <v-btn
                            v-else
                            class="primary-btn pa-3"
                            :to="
                              '/' +
                              (role == 'reception'
                                ? 'reception'
                                : role == 'admin'
                                ? 'admin'
                                : 'dentistryNurse') +
                              '/editrequest/' +
                              data.item.id
                            "
                            ><v-icon> edit</v-icon></v-btn
                          >
                        </div>
                      </template>
                      <template v-slot:cell(serviceCost)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ Number(data.value).toLocaleString() }}
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center primary--text my-2"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          class="align-middle"
                        ></v-progress-circular>
                      </div>
                    </b-table>
                    <v-pagination
                      v-model="CurrentPage"
                      :length="Math.ceil(TotalRows / PerPage)"
                      :total-visible="5"
                      prev-icon="arrow_back"
                      next-icon="arrow_forward"
                      style="float: center"
                    ></v-pagination>
                  </v-card>
                </v-tab-item>
                <!-- پرداخت‌ها -->
                <v-tab-item v-if="role != 'dentistryNurse'">
                  <v-tabs
                    background-color="transparent"
                    vertical
                    v-model="paymentTab"
                  >
                    <v-tab
                      class="tab-title"
                      style="border-radius: 0 !important"
                    >
                      پرداخت‌های انجام شده
                    </v-tab>
                    <v-tab
                      class="tab-title"
                      style="border-radius: 0 !important"
                    >
                      پرداخت‌های معوق
                    </v-tab>
                    <v-tabs-items v-model="paymentTab" class="payments">
                      <v-tab-item @click="paymentType = 'notPartial'">
                        <mobile-text
                          v-if="deviceType == 'mobile'"
                          :title="''"
                        />
                        <v-card class="pa-2" v-else>
                          <v-text-field
                            v-model="paymentFilter"
                            prepend-inner-icon="mdi-magnify"
                            label="جستجو"
                            single-line
                            hide-details
                            clearable
                            rounded
                            filled
                            class="text-right w-80 mb-2 search-input"
                          ></v-text-field>
                          <b-table
                            responsive
                            show-empty
                            :fields="paymentFields"
                            :items="paid"
                            empty-text="پرداختی برای نمایش وجود ندارد"
                            empty-filtered-text="پرداختی برای نمایش وجود ندارد"
                            :busy="Busy"
                            :filter="paymentFilter"
                            :current-page="paymentCurrentPage"
                            :per-page="paymentPerPage"
                            @filtered="paymentOnFiltered"
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index +
                                  paymentPerPage * (paymentCurrentPage - 1) +
                                  1
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(amount)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(description)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                                class="pointer"
                              >
                                <v-tooltip right max-width="600">
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      {{
                                        data.value.length > 20
                                          ? data.value.slice(0, 20) + "..."
                                          : data.value
                                      }}
                                    </span>
                                  </template>
                                  <p class="text-right p-2 text-justify">
                                    {{ data.value }}
                                  </p>
                                </v-tooltip>
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                          <v-pagination
                            v-model="paymentCurrentPage"
                            :length="
                              Math.ceil(paymentTotalRows / paymentPerPage)
                            "
                            :total-visible="5"
                            prev-icon="arrow_back"
                            next-icon="arrow_forward"
                            style="float: center"
                          ></v-pagination>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item @click="paymentType = 'partial'">
                        <mobile-text
                          v-if="deviceType == 'mobile'"
                          :title="''"
                        />
                        <v-card class="pa-2" v-else>
                          <v-text-field
                            v-model="partialFilter"
                            prepend-inner-icon="mdi-magnify"
                            label="جستجو"
                            single-line
                            hide-details
                            clearable
                            rounded
                            filled
                            class="text-right w-80 mb-2 search-input"
                          ></v-text-field>

                          <b-table
                            responsive
                            show-empty
                            :fields="partialFields"
                            :items="unpaid"
                            empty-text="پرداختی برای نمایش وجود ندارد"
                            empty-filtered-text="پرداختی برای نمایش وجود ندارد"
                            :busy="Busy"
                            :filter="partialFilter"
                            :current-page="partialCurrentPage"
                            :per-page="partialPerPage"
                            @filtered="partialOnFiltered"
                            tbody-tr-class="trClass"
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(operation)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                <v-btn
                                  class="primary-btn pa-2"
                                  @click="openPayModal(data.item)"
                                  style="width: 100%; margin-top: 2px"
                                  ><v-icon>mdi-cash-multiple</v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index +
                                  partialPerPage * (partialCurrentPage - 1) +
                                  1
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(cost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(paidCost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(description)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                                class="pointer"
                              >
                                <v-tooltip right max-width="600">
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      {{
                                        data.value.length > 20
                                          ? data.value.slice(0, 20) + "..."
                                          : data.value
                                      }}
                                    </span>
                                  </template>
                                  <p class="text-right p-2 text-justify">
                                    {{ data.value }}
                                  </p>
                                </v-tooltip>
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                          <v-pagination
                            v-model="partialCurrentPage"
                            :length="
                              Math.ceil(partialTotalRows / partialPerPage)
                            "
                            :total-visible="5"
                            prev-icon="arrow_back"
                            next-icon="arrow_forward"
                            style="float: center"
                          ></v-pagination>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                    <v-dialog v-model="paymentItemDialog" width="600">
                      <v-card
                        class="modal-card pa-3"
                        style="max-height: 600px !important; overflow-y: auto"
                      >
                        <v-card-title class="mb-2">
                          <h4>مشاهده جزییات پرداخت</h4>
                          <v-spacer></v-spacer>
                          <v-icon
                            color="red"
                            class="mb-2 ms-2"
                            @click="paymentItemDialog = false"
                            >mdi-close</v-icon
                          >
                        </v-card-title>
                        <v-card-text>
                          <v-row class="mt-3">
                            <v-col>
                              <span class="h2 primary--text">مبلغ :</span>
                              <span class="h2">
                                {{ Number(selected.cost).toLocaleString() }}
                                ریال</span
                              >
                            </v-col>
                          </v-row>
                          <v-row v-if="paymentType == 'partial'">
                            <v-col>
                              <span class="h2 primary--text"
                                >مبلغ پرداخت شده:</span
                              >
                              <span class="h2">
                                {{ Number(selected.paidCost).toLocaleString() }}
                                ریال</span
                              >
                            </v-col>
                            <v-col>
                              <span class="h2 primary--text"
                                >مبلغ باقی‌مانده :</span
                              >
                              <span class="h2">
                                {{
                                  Number(selected.remainAmount).toLocaleString()
                                }}
                                ریال</span
                              >
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <span class="h2 primary--text"
                                >تاریخ پرداخت:</span
                              >
                              <span class="h2"> {{ selected.date }}</span>
                            </v-col>
                            <v-col>
                              <span class="h2 primary--text"
                                >نحوه پرداخت :</span
                              >
                              <span class="h2">
                                {{ selected.transferType }}</span
                              >
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="selected.transferType == 'نقدی-کارتخوان'"
                          >
                            <v-col>
                              <span class="h2 primary--text"
                                >مبلغ کارتخوان:</span
                              >
                              <span class="h2">
                                {{
                                  Number(selected.posAmount).toLocaleString()
                                }}
                                ریال</span
                              >
                            </v-col>
                            <v-col>
                              <span class="h2 primary--text">مبلغ نقدی :</span>
                              <span class="h2">
                                {{
                                  Number(selected.cashAmount).toLocaleString()
                                }}
                                ریال</span
                              >
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="
                              selected.transferType == 'کارت' ||
                              selected.transferType == 'چک'
                            "
                          >
                            <v-col>
                              <div
                                v-if="selected.transferType == 'کارت'"
                                class="cardInfo-box ps-2 py-2 d-flex flex-column"
                              >
                                <div>
                                  <span class="h3 primary--text"
                                    >شماره کارت فرستنده :</span
                                  >
                                  <span
                                    class="h3 float-end"
                                    dir="ltr"
                                    v-if="selected.cardNumberSender"
                                  >
                                    {{
                                      cardNumberSpacer(
                                        selected.cardNumberSender
                                      )
                                    }}</span
                                  >
                                  <span v-else>-</span>
                                </div>
                                <div class="mt-5">
                                  <span class="h3 primary--text"
                                    >شماره کارت گیرنده :</span
                                  >
                                  <span
                                    class="h3 float-end"
                                    dir="ltr"
                                    v-if="selected.cardNumberReciever"
                                  >
                                    {{
                                      cardNumberSpacer(
                                        selected.cardNumberReciever
                                      )
                                    }}</span
                                  >
                                  <span v-else class="h3 float-end" dir="ltr">
                                    -</span
                                  >
                                </div>
                              </div>
                              <div
                                v-if="selected.transferType == 'چک'"
                                class="cardInfo-box ps-2 py-2 d-flex flex-column"
                              >
                                <div>
                                  <span class="h3 primary--text"
                                    >نام بانک :</span
                                  >
                                  <span class="h3">
                                    {{ selected.chequeBankName }}</span
                                  >
                                </div>
                                <div class="mt-5">
                                  <span class="h3 primary--text"
                                    >مبلغ چک :</span
                                  >
                                  <span class="h3">
                                    {{
                                      Number(
                                        selected.chequeAmount
                                      ).toLocaleString()
                                    }}
                                    ریال</span
                                  >
                                </div>
                                <div class="mt-5">
                                  <span class="h3 primary--text"
                                    >شماره چک :</span
                                  >
                                  <span class="h3">
                                    {{ selected.chequeNumber }}</span
                                  >
                                </div>
                                <div class="mt-5">
                                  <span class="h3 primary--text"
                                    >شناسه صیاد :</span
                                  >
                                  <span class="h3">
                                    {{
                                      selected.chequeSayyad
                                        ? selected.chequeSayyad
                                        : "--"
                                    }}</span
                                  >
                                </div>
                                <div class="mt-5">
                                  <span class="h3 primary--text"
                                    >تاریخ چک :</span
                                  >
                                  <span class="h3">
                                    {{ selected.chequeDueDate }}</span
                                  >
                                </div>
                                <div class="mt-5">
                                  <span class="h3 primary--text"
                                    >وضعیت چک :</span
                                  >
                                  <span class="h3">
                                    {{ selected.chequeStatus }}</span
                                  >
                                </div>
                                <div class="mt-5">
                                  <span class="h3 primary--text"
                                    >توضیحات چک :</span
                                  >
                                  <div
                                    class="h3"
                                    style="
                                      line-height: 22px;
                                      text-align: justify;
                                    "
                                  >
                                    {{
                                      selected.chequeDescription
                                        ? selected.chequeDescription
                                        : "توضیحی ثبت نشده است."
                                    }}
                                  </div>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="
                              selected.transferType == 'چک' ||
                              selected.transferType == 'کارت'
                            "
                          >
                            <v-col>
                              <span class="h2 primary--text"
                                >کارمزد انتقال وجه:</span
                              >
                              <span class="h2">
                                {{
                                  Number(
                                    selected.transactionFee
                                  ).toLocaleString()
                                }}
                                ریال
                              </span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col v-if="selected.files">
                              <span class="h2 primary--text">
                                فایل‌ها ({{ selected.files.length }}):</span
                              >
                              <span
                                v-if="!selected.files.length"
                                style="font-size: 15px"
                              >
                                --
                              </span>
                              <div class="d-flex flex-column mt-1 ps-5">
                                <ul>
                                  <li
                                    v-for="(item, index) in selected.files"
                                    :key="index"
                                    class="pt-5"
                                    style="font-size: 15px"
                                  >
                                    <a :href="item.url" target="blank">{{
                                      item.name.length > 50
                                        ? item.name.slice(0, 50) + "..."
                                        : item.name
                                    }}</a>
                                  </li>
                                </ul>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <span class="h2 primary--text">توضیحات :</span>
                              <div
                                class="h3 mt-1"
                                style="line-height: 22px; text-align: justify"
                              >
                                {{ selected.description }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-tabs>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- receipt -->
    <v-card
      class="pa-2"
      v-show="false"
      style="float: left"
      id="Receipt"
      v-if="isMounted"
    >
      <div class="container mb-0" style="border: 2px solid black">
        <div
          style="
            text-align: center;
            margin: auto;
            font-size: 15px;
            border-bottom: 4px solid black;
            padding: 20px;
          "
        >
          <img src="/receiptLogo.png" width="100" height="100" alt="" />
          <br />
          درمانگاه شبانه روزی پارسیان البرز
        </div>
        <div
          style="direction: rtl; font-weight: 900; padding-right: 10px"
          class="ma-3"
        >
          <br />
          {{ "نام مراجعه کننده: " + selected.name }}
          <br />
          {{ "مبلغ : " + Number(selected.cost).toLocaleString() + "ریال" }}
          <br />
          {{
            " مبلغ پرداخت شده: " +
            (Number(selected.paidCost) + Number(payAmount)).toLocaleString() +
            "ریال"
          }}
          <br />
          {{
            "مبلغ باقی‌مانده: " +
            (Number(selected.remained) - Number(payAmount)).toLocaleString() +
            "ریال"
          }}
          <br />
          {{ " تاریخ: " + currentDate }}
          <br />
          {{
            "نوع پرداخت: " +
            paymentTypes.filter((x) => x.value == transferType)[0].text
          }}
          <br />
          <div
            v-if="
              transferType == 'card' ||
              transferType == 'cheque' ||
              transferType == 'cash-pos'
            "
          >
            <div v-if="transferType == 'card'">
              شماره کارت فرستنده:
              {{ cardNumberSender ? cardNumberSender : "-" }}
              <br />
              شماره کارت فرستنده:
              {{ cardNumberReciever ? cardNumberReciever : "-" }}
            </div>
            <div v-if="transferType == 'cash-pos'">
              مبلغ کارتخوان:
              {{ posAmount ? Number(posAmount).toLocaleString() : "-" }} ریال
              <br />
              مبلغ نقدی:
              {{ cashAmount ? Number(cashAmount).toLocaleString() : "-" }} ریال
            </div>
            <div v-if="transferType == 'cheque'">
              نام بانک: {{ chequeBankName ? chequeBankName : "-" }}
              <br />
              شماره چک: {{ chequeNumber ? chequeNumber : "-" }}
              <br />
              شناسه صیاد: {{ chequeSayyad ? chequeSayyad : "-" }}
              <br />
              تاریخ چک: {{ chequeDueDate ? chequeDueDate : "-" }}
              <br />
              توضیحات چک: {{ chequeDescription ? chequeDescription : "-" }}
            </div>
          </div>
        </div>
        <br />
      </div>
      <br />
      <!-- <barcode value="http://my.parsianalborz.com" displayValue="false" width="2" height="60">
        </barcode>   -->
      <h4 style="text-align: center">http://my.parsianalborz.com</h4>
    </v-card>
    <!-- receipt -->
    <v-dialog v-model="payDialog" width="500px">
      <v-card>
        <v-card-title>
          <h4 class="primary--text">پرداخت درخواست</h4>
        </v-card-title>
        <v-card-text class="pb-0">
          <h6>
            <v-row>
              <v-col>نام مراجعه کننده: {{ selected.name }}</v-col>
            </v-row>
            <v-row>
              <v-col
                >مبلغ کل:
                {{ Number(selected.cost).toLocaleString() }} ریال</v-col
              >
              <v-col
                >مبلغ پرداخت شده:
                {{ Number(selected.paidCost).toLocaleString() }} ریال</v-col
              >
            </v-row>
            <v-row>
              <v-col
                >مبلغ باقی‌مانده:
                {{ Number(selected.remained).toLocaleString() }} ریال</v-col
              >
            </v-row>
          </h6>

          <hr />
          <vuetify-money
            v-model="payAmount"
            label="مبلغ (ریال)"
            :valueWhenIsEmpty="whenIsEmpty"
            :options="vMoneyOptions"
            type="number"
            :rules="[validCost]"
            outlined
            dense
            class="mx-auto details-hidden"
          />
          <div class="red--text text-right h5" v-if="payAmount && !validCost()">
            *مبلغ وارد شده از مبلغ مانده بیشتر است
          </div>
          <v-row class="time-row mt-1">
            <v-col
              ><v-select
                v-model="transferType"
                :items="paymentTypes"
                item-text="text"
                item-value="value"
                label="نحوه پرداخت"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            class="time-row"
            v-if="
              transferType == 'card' ||
              transferType == 'cheque' ||
              transferType == 'cash-pos'
            "
          >
            <v-col>
              <div v-if="transferType == 'card'">
                <div
                  style="border: 1px grey solid; border-radius: 8px"
                  class="px-3 py-2"
                >
                  <h5>اطلاعات کارت‌ها</h5>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    class="mb-4"
                    v-model="cardNumberSender"
                    label="شماره کارت فرستنده*"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    v-model="cardNumberReciever"
                    label="شماره کارت گیرنده*"
                  >
                  </v-text-field>
                </div>
              </div>
              <div v-if="transferType == 'cash-pos'">
                <div
                  style="border: 1px grey solid; border-radius: 8px"
                  class="px-3 py-2"
                >
                  <h6 class="py-3 red--text" v-if="!cashPosValid('payment')">
                    ! مجموع نقدی و کارتخوان با مبلغ نهایی برابر نمی‌باشد.
                  </h6>
                  <vuetify-money
                    v-model="posAmount"
                    label="*مبلغ کارتخوان(ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    @input="onPosInput('payment')"
                    outlined
                    dense
                    class="mx-auto mb-2 details-hidden"
                  />
                  <vuetify-money
                    v-model="cashAmount"
                    label="*مبلغ نقدی(ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    outlined
                    dense
                    class="mx-auto details-hidden"
                  />
                </div>
              </div>
              <div v-if="transferType == 'cheque'">
                <div
                  style="border: 1px grey solid; border-radius: 8px"
                  class="px-3 py-2"
                >
                  <h5>اطلاعات چک</h5>
                  <v-text-field
                    outlined
                    dense
                    v-model="chequeBankName"
                    label="نام بانک*"
                    class="mb-4"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="chequeNumber"
                    label="شماره چک*"
                    class="mb-4"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="chequeSayyad"
                    label="شناسه صیاد"
                    class="mb-4"
                  >
                  </v-text-field>
                  <span id="newChequeDate">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="chequeDueDate"
                      label=" تاریخ چک*"
                      :editable="true"
                      class="date-input mb-4"
                    >
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="chequeDueDate"
                    element="newChequeDate"
                    color="#00a7b7"
                  />
                  <v-textarea
                    outlined
                    dense
                    type="text"
                    v-model="chequeDescription"
                    label=" توضیحات چک"
                  >
                  </v-textarea>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="red-btn"
            outlined
            :disabled="isPaying"
            @click="payDialog = false"
          >
            لغو
          </v-btn>
          <v-btn
            class="primary-btn"
            outlined
            :loading="isPaying"
            @click="submitPayment(selected.type)"
            :disabled="!checkCost() || !cashPosValid('payment')"
          >
            ثبت پرداخت
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";
import moment from "moment-jalaali";
const MobileText = () => import("@/components/MobileText");
function checkCodeMeli(code) {
  var L = code.length;

  if (L < 8 || parseInt(code, 10) == 0) return false;
  code = ("0000" + code).substr(L + 4 - 10);
  if (parseInt(code.substr(3, 6), 10) == 0) return false;
  var c = parseInt(code.substr(9, 1), 10);
  var s = 0;
  for (var i = 0; i < 9; i++) s += parseInt(code.substr(i, 1), 10) * (10 - i);
  s = s % 11;
  return (s < 2 && c == s) || (s >= 2 && c == 11 - s);
}
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    MobileText,
  },
  data() {
    return {
      tab: null,
      paymentTab: null,
      userData: {},
      history: {},
      states: ["فعال", "غیرفعال"],
      genders: [
        { value: "زن", text: "زن" },
        { value: "مرد", text: "مرد" },
      ],
      bloodTypes: [
        { value: "A+", text: "+A" },
        { value: "A-", text: "-A" },
        { value: "B-", text: "-B" },
        { value: "B+", text: "+B" },
        { value: "AB+", text: "+AB" },
        { value: "AB-", text: "-AB" },
        { value: "O+", text: "+O" },
        { value: "O-", text: "-O" },
      ],
      CurrentPage: 1,
      PerPage: 10,
      TotalRows: "",
      Filter: "",
      Busy: false,
      Items: [],
      role: "",
      paymentFilter: "",
      partialFilter: "",
      paymentCurrentPage: 1,
      paymentPerPage: 10,
      paymentTotalRows: "",
      partialCurrentPage: 1,
      partialPerPage: 10,
      partialTotalRows: "",
      paymentFields: [
        { key: "index", label: "#" },
        { key: "date", label: "تاریخ" },
        { key: "amount", label: "مبلغ (ریال)" },
        { key: "transferType", label: "نحوه پرداخت" },
        { key: "description", label: "توضیحات" },
      ],
      partialFields: [
        { key: "index", label: "#" },
        { key: "date", label: "تاریخ و  ساعت" },
        { key: "cost", label: "مبلغ (ریال)" },
        { key: "paidCost", label: "مبلغ پرداخت شده (ریال)" },
        { key: "type", label: "نوع درخواست" },
        { key: "operation", label: "پرداخت" },
        //{ key: "description", label: "توضیحات" },
      ],
      paymentItemDialog: false,
      selected: {},
      paymentType: "notPartial",
      paid: [],
      unpaid: [],
      transferType: "cash",
      cardNumberSender: "",
      cardNumberReciever: "",
      chequeBankName: "",
      chequeAmount: "",
      chequeNumber: "",
      chequeSayyad: "",
      chequeDueDate: "",
      chequeDescription: "",
      payDialog: false,
      payAmount: "",
      isMounted: false,
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      isPaying: false,
      cashAmount: 0,
      posAmount: 0,
      reciept: {},
      infoLoading: false,
    };
  },

  methods: {
    cardNumberSpacer(number) {
      number = number.match(new RegExp(".{1,4}", "g")).join("-");
      return number;
    },
    openPayModal(item) {
      this.selected = JSON.parse(JSON.stringify(item));
      this.selected.remained = this.selected.cost - this.selected.paidCost;
      this.payAmount = this.selected.remained;
      this.transferType = "cash";
      this.cardNumberSender = "";
      this.cardNumberReciever = "";
      this.chequeBankName = "";
      this.chequeAmount = "";
      this.chequeNumber = "";
      this.chequeSayyad = "";
      this.chequeDueDate = "";
      this.chequeDueDate = "";
      this.chequeDescription = "";
      this.cashAmount = 0;
      this.posAmount = 0;
      this.payDialog = true;
    },
    submitPayment(type) {
      this.isPaying = true;
      if (type == "درمانگاه") {
        this.$http
          .post(
            this.baseUrl + "/clinic/financial/payment/pay",
            {
              visitId: this.selected.id,
              paidCost: this.payAmount,
              transferType: this.transferType,
              chequeAmount: this.transferType == "cheque" ? this.payAmount : "",
              chequeNumber:
                this.transferType == "cheque" ? this.chequeNumber : "",
              chequeBankName:
                this.transferType == "cheque" ? this.chequeBankName : "",
              chequeDueDate:
                this.transferType == "cheque" ? this.chequeDueDate : "",
              chequeSayyad:
                this.transferType == "cheque" ? this.chequeSayyad : "",
              chequeDescription:
                this.transferType == "cheque" ? this.chequeDescription : "",
              cardNumberSender:
                this.transferType == "card" ? this.cardNumberSender : "",
              cardNumberReciever:
                this.transferType == "card" ? this.cardNumberReciever : "",
              cash: this.transferType == "cash-pos" ? this.cashAmount : "",
              pos: this.transferType == "cash-pos" ? this.posAmount : "",
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.isPaying = false;
            if (res.status == 200) {
              this.toast(res.data, "success");
              this.payDialog = false;
              this.patientVisDetails();
              this.printReceipt();
              this.isMounted = false;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.isPaying = false;
            this.toast("خطا: " + err.response.data, "error");
          });
      } else if (type == "خدمات در منزل") {
        this.$http
          .post(
            this.baseUrl + "/clinic/homeCare/financial/pay",
            {
              outRequestId: this.selected.id,
              paidCost: this.payAmount,
              transferType: this.transferType,
              cardNumberSender:
                this.transferType == "card" ? this.cardNumberSender : "",
              cardNumberReciever:
                this.transferType == "card" ? this.cardNumberReciever : "",
              cash: this.transferType == "cash-pos" ? this.cashAmount : "",
              pos: this.transferType == "cash-pos" ? this.posAmount : "",
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.isPaying = false;
            if (res.status == 200) {
              this.toast(res.data, "success");
              this.payDialog = false;
              this.patientVisDetails();
              this.printReceipt();
              this.isMounted = false;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.isPaying = false;
            this.toast("خطا: " + err.response.data, "error");
          });
      } else if (type == "آزمایشگاه") {
        this.$http
          .post(
            this.baseUrl + "/clinic/lab/payment/pay",
            {
              labRequestId: this.selected.id,
              paidCost: this.payAmount,
              transferType: this.transferType,
              cardNumberSender:
                this.transferType == "card" ? this.cardNumberSender : "",
              cardNumberReciever:
                this.transferType == "card" ? this.cardNumberReciever : "",
              cash: this.transferType == "cash-pos" ? this.cashAmount : "",
              pos: this.transferType == "cash-pos" ? this.posAmount : "",
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.isPaying = false;
            if (res.status == 200) {
              this.toast(res.data, "success");
              this.payDialog = false;
              this.patientVisDetails();
              this.printReceipt();
              this.isMounted = false;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.isPaying = false;
            this.toast("خطا: " + err.response.data, "error");
          });
      }
    },
    validCost(cost) {
      // NOTE (m-isID) to check requested amount is not bigger than remained cost
      return this.payAmount
        ? this.payAmount <= this.selected.remained
          ? true
          : false
        : true;
    },
    checkCost() {
      // NOTE (m-isID) to check the validity of the national code
      if (this.payAmount) {
        if (this.payAmount <= this.selected.remained) {
          if (this.transferType == "card") {
            if (this.cardNumberSender && this.cardNumberReciever) {
              return true;
            } else return false;
          } else if (this.transferType == "cheque") {
            if (
              this.chequeBankName &&
              this.chequeNumber &&
              this.chequeDueDate
            ) {
              return true;
            } else return false;
          } else if (this.transferType == "cash-pos") {
            if (this.cashAmount && this.posAmount) {
              return true;
            } else return false;
          } else return true;
        } else return false;
      } else return false;
    },
    onPosInput(type) {
      if (type == "payment") {
        if (parseInt(this.posAmount) < parseInt(this.payAmount)) {
          this.cashAmount = parseInt(this.payAmount) - parseInt(this.posAmount);
        } else {
          this.cashAmount = 0;
        }
      }
    },
    cashPosValid(type) {
      if (this.transferType == "cash-pos") {
        if (this.cashAmount && this.posAmount) {
          if (type == "payment") {
            if (
              parseInt(this.payAmount) ==
              parseInt(this.cashAmount) + parseInt(this.posAmount)
            ) {
              return true;
            } else return false;
          }
        } else return false;
      } else {
        return true;
      }
    },
    printReceipt() {
      // printing receipt information for user
      var myWindow = window.open("#", "Receipt", "height=auto,width=800");
      myWindow.document.write(
        "<html><head><link rel='stylesheet' href='/receipt.css'><title>Receipt</title>"
      );
      myWindow.document.write("</head><body>");
      myWindow.document.write(document.getElementById("Receipt").innerHTML);
      myWindow.document.write("</body></html>");
      myWindow.document.close();

      myWindow.onload = function () {
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
          myWindow.close();
        }, 500);
      };
    },
    //checking email and code melli
    validateEmail(email) {
      let result = "";
      if (email) {
        var re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        result = re.test(String(email).toLowerCase())
          ? true
          : "ایمیل وارد شده صحیح نمی‌باشد";
        return result;
      } else return true;
    },
    isID(id) {
      // NOTE (m-isID) to check the validity of the national code
      return id
        ? checkCodeMeli(id)
          ? true
          : "کدملی وارد شده صحیح نمی‌باشد"
        : true;
    },
    //to here
    patientVisDetails() {
      // sendig patient id and getting visit detail from server
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/visitDetails",
          {
            patientId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.Items = res.data.allVisits;
            this.paid = res.data.paid;
            this.unpaid = res.data.unpaid;
            this.TotalRows = this.Items.length;
            this.partialTotalRows = this.unpaid.length;
            this.paymentTotalRows = this.paid.length;
            this.isMounted = true;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    patientInfo() {
      //setting patient information
      this.infoLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/id",
          {
            patientId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.infoLoading = false;
            this.vLoading = false;
            this.userData = res.data.profile;
            this.history = res.data.history;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.infoLoading = false;
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.infoLoading = false;
        });
    },
    editPatient() {
      //sending changes to server
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/edit",
          {
            patientId: this.$route.params.id,
            firstName: this.userData.firstName,
            lastName: this.userData.lastName,
            fatherName: this.userData.fatherName,
            insuranceId: this.userData.insuranceId,
            supplementaryInsurance: this.userData.supplementaryInsurance,
            insurNo: this.userData.insurNo,
            natCode: this.userData.natCode,
            gender: this.userData.gender,
            status: this.userData.status,
            birthday: this.userData.birthday,
            city: this.userData.city,
            country: this.userData.country,
            state: this.userData.state,
            address: this.userData.address,
            tel: this.userData.tel,
            email: this.userData.email,
            bloodType: this.userData.bloodType,
            diseases: this.history.diseases,
            allergies: this.history.allergies,
            drugs: this.history.drugs,
            insuranceExpirationDate: this.userData.insuranceLastSheet
              ? ""
              : this.userData.insuranceExpirationDate,
            insuranceLastSheet: this.userData.insuranceLastSheet,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.toast("بیمار با موفقیت ویرایش گردید.", "success");
            this.patientInfo();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },

    //onRowSelected(item) {
    //  this.selected = item;
    //  setTimeout(() => {
    //    this.paymentType == "partial" ? this.selected.remainAmount = item.amount - item.paidAmount : ''
    //    this.paymentItemDialog = true;
    //  }, 100);

    //  //connect to back
    //  //this.$http
    //  //  .post(
    //  //    this.baseUrl + "/clinic/financial/document/show",
    //  //    {
    //  //      financialId: item.id,
    //  //    },
    //  //    {
    //  //      headers: {
    //  //        Authorization: "Bearer: " + localStorage.getItem("token"),
    //  //      },
    //  //    }
    //  //  )
    //  //  .then((res) => {
    //  //    if (res.status == 200) {
    //  //      this.selected = res.data;
    //  //    } else {
    //  //      this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
    //  //    }
    //  //  })
    //  //  .catch((err) => {
    //  //    this.toast("خطا: " + err.response.data, "error");
    //  //  });
    //  //this.paymentItemDialog = true;
    //},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
    paymentOnFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.paymentTotalRows = filteredItems.length;
      this.paymentCurrentPage = 1;
    },
    partialOnFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.partialTotalRows = filteredItems.length;
      this.partialCurrentPage = 1;
    },
  },
  computed: {
    ...mapGetters(["insurTypes", "paymentTypes"]),
    Fields() {
      let fields = {};
      if (this.role == "reception") {
        fields = [
          { key: "index", label: "#" },
          { key: "date", label: "تاریخ" },
          { key: "doctorName", label: "نام پزشک" },
          { key: "status", label: "وضعیت" },
          { key: "visitType", label: "نوع مراجعه" },
          { key: "operation", label: "جزییات " },
        ];
      } else {
        fields = [
          { key: "index", label: "#" },
          { key: "date", label: "تاریخ" },
          { key: "doctorName", label: "نام پزشک" },
          { key: "serviceCost", label: "قیمت (ریال)" },
          { key: "visitType", label: "نوع مراجعه" },
          { key: "status", label: "وضعیت" },
          { key: "operation", label: "جزییات " },
        ];
      }
      return fields;
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.vLoading = true;
    this.patientInfo();
    this.patientVisDetails();
  },
};
</script>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .v-tabs--vertical {
    display: flex !important;
    flex-direction: column !important;
  }
  .v-tab {
    border-bottom: 1px solid #ccc;
  }
  .v-window {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
.modal-card {
  .cardInfo-box {
    border-right: 2px solid #ccc;
    width: fit-content !important;
  }
  .v-file-input .v-file-input__text.v-file-input__text--chips {
    display: none !important;
  }
  .files-box {
    border: 1px solid rgb(172, 172, 172);
    text-align: right;
    font-size: 14px;
    margin-top: 35px;
    padding: 10px;
    border-radius: 7px;
    position: relative;
    ul {
      li {
        text-decoration: none;
      }
    }
    .files-title {
      position: absolute;
      top: -9px;
      right: 20px;
      background-color: #fff;
      font-size: 14px;
      padding: 0 6px;
    }
  }
}

.payments {
  .trClass {
    cursor: pointer !important;
    &:hover {
      background-color: rgb(236, 236, 236) !important;
    }
    &.table-active {
      background-color: #fff !important;
    }
  }
}
</style>
